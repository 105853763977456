import {useState, useEffect, useContext} from "react";
import {LikeCard, LikeNotification} from "../Notification/Notification";

import axios from 'axios';

import React from 'react';

import GetHeaders from '../../Misc/GetHeaders';
import './style.css';
import {BACKEND_BASE_URL} from "../../config";
import Loading from "../Loading/Loading";
import {AppPersistenceContext} from "../MainCard/MainCard";
import SendSuperLike from "../SendSuperLike/SendSuperLike";
import HandHeart from "../../Icons/Hand Heart.svg";
import Cards from "../../Icons/Cards";


export default function MyNotifications() {
    const [superlikeUser, setSuperlikeUser] = useState<number | null>(null)
    const {notifications, setNotifications, navigate} = useContext(AppPersistenceContext);

    useEffect(() => {
        axios.post(
            BACKEND_BASE_URL + '/api/v1/get_likes', null, GetHeaders()
        ).then((response: { data: { likes: LikeNotification[] } }) =>
            (setNotifications(response.data.likes),console.log(response))
        );
    }, []);

    if (!notifications) return <Loading/>
    if (notifications.length === 0) return (
        <div style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '8px',
            borderRadius: '16px',
            border: '1px solid #E2E2E2'
        }}>
            <img src={HandHeart} style={{ width: '128px', marginBottom: '16px' }}/>
            <p style={{fontSize: '16px', fontWeight: 500}}>Лайки закончились</p>
            <p style={{fontSize: '14px', color: '#5A5A5A'}}>Пора собрать новые</p>
            <button
                onClick={() => navigate(0)}
                style={{
                    paddingLeft: '12px',
                    paddingRight: '12px',
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    backgroundColor: '#AA4EC1',
                    borderRadius: '24px',
                    color: 'white',
                    border: 'none',
                    fontSize: '14px',
                    cursor: 'pointer',
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center'
                }}
            >
                <Cards style={{ width: '16px' }}/>
                Вернуться на главную
            </button>
        </div>
    )
    return (
        <div style={{ height: '100%', overflowY: 'auto'}}>
            <SendSuperLike userId={superlikeUser || -1} open={superlikeUser !== null} onDismiss={() => setSuperlikeUser(null)} />
            <div style={{ gap: '8px', display: 'grid', gridTemplateColumns: '1fr', gridAutoFlow: 'flow'}}>
            {notifications.map((notification) =>
                <LikeCard notification={notification} onSuperlike={() => setSuperlikeUser(notification.user_id)} key={notification.user_id} />
            )}
            </div>
        </div>
    );
}

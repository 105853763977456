import ContentWrapper from "../ContentWrapper/ContentWrapper";
import Header from "../Header/Header";
import NavigationPanel from "../NavigationPanel/NavigationPanel";

import {HomeCarousel, UserSuggestion} from "../Carusel/Carusel";
import Categories, {Category} from "../Categories/Categories";
import {createContext, useEffect, useState} from "react";
import axios from 'axios';
import GetHeaders from "../../Misc/GetHeaders";
import MyProfile, {Profile} from "../MyProfile/MyProfile";
import MyNotifications from "../MyNotifications/MyNotifications";
import {BACKEND_BASE_URL} from "../../config";
import {LikeNotification} from "../Notification/Notification";


declare const Telegram: any;

export const AppPersistenceContext = createContext<{
    suggestions: UserSuggestion[] | null;
    setSuggestions: (val: UserSuggestion[] | null) => void;
    notifications: LikeNotification[] | null;
    setNotifications: (val: LikeNotification[] | null) => void;
    profileData: Profile | null;
    updateProfile: () => void;
    categories: Category[] | null;
    updateCategories: () => void;
    page: number;
    navigate: (page: number) => void;
}>(null as any);

export default function MainCard() {
    const [appPageIndex, setAppPage] = useState(0);
    const navigate = (AppPageIndex: number) => { setAppPage(AppPageIndex) };
    const [suggestions, setSuggestions] = useState<UserSuggestion[] | null>(null);
    const [notifications, setNotifications] = useState<LikeNotification[] | null>(null)
    const [profileData, setProfileData] = useState<Profile | null>(null)
    const [categories, setCategories] = useState<Category[] | null>(null);

    function updateProfile() {
        axios.post(BACKEND_BASE_URL + '/api/v1/get_profile', {user_id: Telegram.WebApp.initDataUnsafe.user.id}, GetHeaders())
            .then((response: any) => { setProfileData(response.data.recommend) });
    }

    function updateCategories() {
        axios.post(BACKEND_BASE_URL + '/api/v1/get_categories',null, GetHeaders())
            .then((response: any) => { setCategories(response.data.categories) });
    }

    useEffect(() => {
        if (!profileData) updateProfile();
        if (!categories) updateCategories();
    }, [categories, profileData])

    const CurrentPage = [
        HomeCarousel, Categories, MyNotifications, MyProfile
    ][appPageIndex]

    return (
        <AppPersistenceContext.Provider value={{
            suggestions, setSuggestions,
            notifications, setNotifications,
            profileData, updateProfile,
            categories, updateCategories,
            page: appPageIndex, navigate
        }}>
            <div style={{ width: '100%', height: '100vh', backgroundColor: '#EFEEF4', overflowX: 'hidden', justifyContent: 'center', position: 'relative', display: 'flex', flexDirection: 'column' }}>
                <Header />
                <ContentWrapper>
                    <CurrentPage />
                </ContentWrapper>
                <NavigationPanel />
            </div>
        </AppPersistenceContext.Provider>
    );
}

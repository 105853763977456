import {ComponentProps} from "react";

export default function Cards(props: ComponentProps<'svg'>) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M1.87181 9.35948C1.46084 7.8257 1.25535 7.05881 1.97641 6.3549C2.69748 5.651 4.0635 5.28497 6.79555 4.55292C9.52761 3.82087 10.8936 3.45484 11.87 3.70391C12.8465 3.95298 13.0519 4.71987 13.4629 6.25365L15.8604 15.2012C16.2714 16.735 16.4769 17.5018 15.7558 18.2058C15.0347 18.9097 13.6687 19.2757 10.9367 20.0077C8.20461 20.7398 6.83858 21.1058 5.86217 20.8567C4.88576 20.6077 4.68027 19.8408 4.26929 18.307L1.87181 9.35948Z"
                fill="currentColor"
            />
            <path
                d="M14.8818 5.75277C14.8725 5.71812 14.8632 5.68329 14.8538 5.64832C15.5154 5.77486 16.3508 5.99872 17.4532 6.29409C19.7299 6.90413 20.8682 7.20915 21.4638 7.81559C22.0594 8.42203 21.8796 9.09306 21.52 10.4351L19.4222 18.2642C19.0626 19.6063 18.8828 20.2773 18.0638 20.5047C17.4516 20.6747 16.6611 20.5472 15.3502 20.2173C15.8923 19.9777 16.3896 19.6833 16.8037 19.2791C17.4859 18.6131 17.7551 17.8312 17.7321 16.9745C17.7147 16.3214 17.5186 15.5919 17.3396 14.9255L14.8818 5.75277Z"
                fill="currentColor"
            />
        </svg>
    )
}

import axios from 'axios';

declare const Telegram: any;
const API_V1_URL: string = 'http://127.0.0.1:8080/api/v1';


// Api requests wrapper
export default function GetHeaders() {
    const token = 'Bearer ' + Telegram.WebApp.initData;
    const header = { headers : {
        'Authorization': token,
        'Access-Control-Allow-Origin': '*'
    }};
    return header;

    
}
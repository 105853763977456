import React, {useContext, useEffect, useState} from 'react';
import {TopicCarousel} from '../Carusel/Carusel';
import {HightTag} from '../Tag/Tag';
import {AppPersistenceContext} from "../MainCard/MainCard";
import Loading from "../Loading/Loading";

export type Category = {
    name: string;
    photo: string;
}


function Topic(props: any) {
    return (
        <div style={{ width: '100%', height: '200px', backgroundImage: `url(${props.image})`, backgroundPosition: 'center', backgroundSize:'cover', borderRadius: '16px'}}>
            <button style={{display: 'table-cell', verticalAlign: 'middle', margin: '1.5px', marginTop: '80px', backgroundColor: 'white', borderRadius: '12px', height: '39px', border: 'none'}} onClick={() => (props.handleClick(props.name))}>
                <p style={{ marginLeft: '12px', marginRight: '12px', fontSize: '12px', }}>{props.name}</p>
            </button>
        </div>
    )
}

export default function Categories() {
    const { categories: categories, updateCategories } = useContext(AppPersistenceContext)
    const [selected, setSelected] = useState<string>('');
    const handleClick = (selectedTopic: string) => { (setSelected(selectedTopic)) };

    useEffect(updateCategories, []);

    if (!categories) return <Loading />
    if (selected == '') {
        return (
            <div style={{ width: '100%', height: '100%', textAlign: 'center'}}>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0,1fr))', gap: '24px'}}>
                    { categories.map((topic: { name: string; photo: string;}) => <Topic name={topic.name} image={topic.photo} handleClick={handleClick}/>)}
                </div>
            </div>
        );
    }
    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', gap: '8px', overflowX: 'auto'}}>
                <HightTag tagname={"Назад"} handleClick={() => handleClick('')} />
                <HightTag tagname={selected} currentTopic={true}/>
                {categories.map((topic: {name: string}) => topic.name !== selected ? (<HightTag tagname={topic.name} handleClick={handleClick} key={topic.name} />) : null)}
            </div>
            <div style={{ flexGrow: '2', paddingTop: '12px'}}>
                <TopicCarousel topic={selected} />
            </div>
        </div>
    );
}
import React, {useContext, useState} from 'react';
import Modal from 'react-modal';

import {sendSuperLike} from '../Buttons/Buttons';
import {AppPersistenceContext} from "../MainCard/MainCard";

Modal.setAppElement('#root');

export default function SendSuperLike(
    {
        userId,
        open,
        onDismiss,
        onSent = () => {}
    }: {
        userId: number,
        open: boolean,
        onDismiss: () => void,
        onSent?: () => void
    }
) {
    const [content, setContent] = useState("");
    const {profileData, updateProfile} = useContext(AppPersistenceContext)
    const sufficientSuperlikes = profileData && profileData.super_like > 0

    function dismiss() {
        setContent("");
        onDismiss();
    }

    function handleSubmit() {
        sendSuperLike(userId, content);
        setTimeout(updateProfile, 1000);
        dismiss();
        onSent();
    }

    return (
        <Modal
            isOpen={open}
            onRequestClose={dismiss}
            style={{
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-50%',
                    borderRadius: '16px',
                    transform: 'translate(-50%, -50%)',
                    width: 'calc(100vw - 40px - 16px)'
                },
            }}
        >
            <div style={{textAlign: 'center', width: '100%', display: 'flex', flexDirection: 'column', gap: '24px', alignItems: 'center'}}>
                <div style={{marginTop: '16px', flexWrap: 'wrap'}}>
                    <p style={{fontWeight: 'bold', fontSize: '22px'}}>Суперлайк</p>
                </div>
                <p style={{color: '#5A5A5A', textAlign: 'center'}}>
                    Найдите партнера в разы быстрее с отправкой сообщений!
                </p>
                <div style={{borderRadius: '999px', padding: '16px 20px', width: 'fit-content', background: '#AA4EC1', color: '#ffffff'}}>
                    Доступно {profileData?.super_like || 0} штук
                </div>
                <textarea
                    style={{backgroundColor: '#ffffff', padding: '16px 20px', borderRadius: '12px', resize: 'none', border: '1px solid #E2E2E2', width: '100%', fontFamily: 'Onest', boxSizing: 'border-box'}}
                    placeholder='Сообщение для пользователя' value={content}
                    onChange={e => setContent(e.target.value)}
                />
                <div style={{textAlign: 'right', display: 'flex', justifyContent: 'flex-end', gap: '8px', width: '100%'}}>
                    <button
                        style={{paddingLeft: '20px', paddingRight: '20px', paddingTop: '12px', paddingBottom: '12px', background: 'transparent', border: 'none', color: '#5A5A5A', fontSize: '14px'}}
                        onClick={dismiss}
                    >
                        Отмена
                    </button>
                    <button
                        onClick={() => handleSubmit()}
                        style={{
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            paddingTop: '16px',
                            paddingBottom: '16px',
                            backgroundColor: sufficientSuperlikes ? '#AA4EC1' : '#856d8a',
                            borderRadius: '24px',
                            color: 'white',
                            border: 'none',
                            fontSize: '14px',
                            cursor: sufficientSuperlikes ? 'pointer' : 'not-allowed'
                        }}
                        disabled={!sufficientSuperlikes}
                    >
                        {sufficientSuperlikes ? "Отправить" : "Недостаточно"}
                    </button>
                </div>
            </div>
        </Modal>
    );
}

import {ComponentProps} from "react";

export default function Share(props: ComponentProps<'svg'>) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M13.9549 5.18356L18.9328 9.6083C19.8634 10.4355 20.3286 10.8491 20.5001 11.3374C20.6507 11.7663 20.6507 12.2337 20.5001 12.6626C20.3286 13.151 19.8634 13.5645 18.9328 14.3917L13.9549 18.8165C13.5326 19.1918 13.3215 19.3795 13.1422 19.3862C12.9864 19.3921 12.8367 19.3249 12.7376 19.2046C12.6235 19.0661 12.6235 18.7836 12.6235 18.2185V15.4286C10.1954 15.4286 7.63081 16.2084 5.75819 17.5928C4.7833 18.3135 4.29583 18.6739 4.11017 18.6596C3.92919 18.6458 3.81434 18.5751 3.72044 18.4198C3.62411 18.2604 3.70919 17.7625 3.87936 16.7667C4.98434 10.3006 9.43431 8.57144 12.6235 8.57144V5.78149C12.6235 5.21647 12.6235 4.93397 12.7376 4.79546C12.8367 4.67514 12.9864 4.60795 13.1422 4.61378C13.3215 4.6205 13.5326 4.80818 13.9549 5.18356Z"
                fill="currentColor"
            />
        </svg>
    )
}

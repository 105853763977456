export default function Tag({tagname, type = 'default'}: {tagname: string, type?: 'default' | 'darker'}) {
    return (
        <div style={{ backgroundColor: type === 'darker' ? '#EFEEF4' : '#FFFFFF', display: 'inline-block', borderRadius: '16px', height: '27px', paddingTop:'5px'}}>
            <h1 style={{ fontSize: '12px', color: 'black', marginLeft: '12px', marginRight: '12px', marginTop: '3px'}}>{tagname}</h1>
        </div>
    );
}

export function HightTag(props: any) {
    return (
        <button className='hightTag' style={props.currentTopic ? { backgroundColor: '#AA4EC1', display: 'inline-block', borderRadius: '16px', paddingTop:'8px', border: 'none', paddingBottom: '8px'} : { backgroundColor: '#FFFFFF', display: 'inline-block', borderRadius: '16px', paddingTop:'8px', border: 'none', paddingBottom: '8px'}} onClick={() => props.handleClick(props.tagname)}>
            <h1 style={props.currentTopic ? { fontSize: '16px', color: 'white', marginLeft: '12px', marginRight: '12px', whiteSpace: 'nowrap'} : { fontSize: '16px', color: 'black', marginLeft: '12px', marginRight: '12px', whiteSpace: 'nowrap'}}>{props.tagname}</h1>
        </button>
    );
}
import Spacer from 'react-spacer';

import HashtagIco from '../../Icons/HashTagIcon.svg';
import MyAccountIco from '../../Icons/User.svg';
import MyAccountOutlineIco from '../../Icons/UserOutline.svg';
import {useContext} from "react";
import {AppPersistenceContext} from "../MainCard/MainCard";

export default function Header() {
    const {page, navigate} = useContext(AppPersistenceContext);

    return (
        <div style={{ display: 'flex', gap: '8px', paddingTop: '16px', paddingLeft: '16px', paddingRight: '16px'}}>
            <img src={ HashtagIco } alt="" />
            <p style={{ fontWeight: 'bold', fontSize: '22px'}}>CodeMates</p>

            <Spacer grow='1'/>

            <button style={{ border: 'none', backgroundColor: 'transparent'}} onClick={event => navigate(3)}>
                <img src={ page === 3 ? MyAccountIco : MyAccountOutlineIco } alt="" />
            </button>

        </div>
    );
}
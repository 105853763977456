import {DockbarButtonWrapper} from "../Buttons/Buttons";
import {useContext} from "react";
import {AppPersistenceContext} from "../MainCard/MainCard";
import Cards from "../../Icons/Cards";
import Selection from "../../Icons/Selection";
import Like from "../../Icons/Like";

export default function NavigationPanel() {
    const { page, navigate } = useContext(AppPersistenceContext)

    const navItems = [Cards, Selection, Like]

    return (
        <div style={{ width: '100%', height: '64px', display: 'flex', justifyContent: 'center', gap: '15%', alignItems: 'center'}}>
            {navItems.map((icon, i) => <DockbarButtonWrapper icon={icon} onClick={() => navigate(i)} highlight={page === i} />)}
        </div>
    );
}
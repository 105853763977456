import { HashLoader } from "react-spinners";
import { CSSProperties, useState} from "react";

export default function Loading() {
    const [loading, setLoading] = useState();
    const override: CSSProperties = {
        display: 'block',
        margin: '0 auto',
        borderColor: 'black',
    }

    return (
        <div style={{marginTop: '25vh', height: '100%', width: '100%'}}>
            <HashLoader
                color='black'
                loading={loading}
                cssOverride={override}
                size={50}
            />
        </div>
    ); 
}
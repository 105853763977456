import axios from 'axios';
import GetHeaders from '../../Misc/GetHeaders';
import {BACKEND_BASE_URL} from "../../config";
import {ComponentType, ReactNode} from "react";

export function sendLike(userId: number, liked: boolean) {
    axios.post(BACKEND_BASE_URL + '/api/v1/like', {
        user_id: userId,
        like: liked
    }, GetHeaders()).then(response => console.log(response))
}

export function sendSuperLike(user_id_: number, text_string: string) {
    axios.post(BACKEND_BASE_URL + '/api/v1/superlike', {user_id: user_id_, 'text': text_string}, GetHeaders()).then(response => console.log(response))
}

export function DockbarButtonWrapper({icon: Icon, highlight = false, onClick}: {icon: ComponentType, highlight?: boolean, onClick: () => void}) {
    return (
        <button
            style={{
                border: 'none',
                backgroundColor: highlight ? '#E9DFEF' : 'transparent',
                color: highlight ? '#AA4EC1' : '#5A5A5A',
                width: '72px',
                height: '48px',
                borderRadius: '36px',
            }}
            onClick={onClick}
        >
            <Icon />
        </button>
    );
}

export function LikesButtonWrapper({onClick, children}: {onClick: () => void, children: ReactNode}) {
    return (
        <button style={{ width: '64px', height: '64px', backgroundColor: 'rgba(0,0,0,0.5)', borderRadius: '60px', border: '2px solid rgba(255,255,255,0.2)', color: 'currentColor' }} onClick={onClick}>
            {children}
        </button>
    );
}

import { AboutMe, Referal, MySuperLikes} from "../Notification/Notification";
import Spacer from "react-spacer";
import Tag from "../Tag/Tag";

import Star from '../../Icons/Star';
import Edit from '../../Icons/Edit';
import Users from '../../Icons/Users';
import Share from '../../Icons/Share';

import axios from 'axios';
import GetHeaders from "../../Misc/GetHeaders";
import React, {useContext, useEffect} from "react";
import {BACKEND_BASE_URL} from "../../config";
import {AppPersistenceContext} from "../MainCard/MainCard";
import Loading from "../Loading/Loading";

declare const Telegram: any;

function onProfileEdit() {
    axios.post(BACKEND_BASE_URL + "/api/v1/edit_profile", null, GetHeaders()).then((response: any) => (console.log(response), Telegram.WebApp.close()));
}

function onFriendsInvite() {
    axios.post(BACKEND_BASE_URL + "/api/v1/invite_friends", null, GetHeaders()).then(Telegram.WebApp.close());
}

function onChangeSkills() {
    axios.post(BACKEND_BASE_URL + "/api/v1/change_skills", null, GetHeaders()).then(Telegram.WebApp.close());
}

function onChangeAbout() {
    axios.post(BACKEND_BASE_URL + "/api/v1/change_about", null, GetHeaders()).then(Telegram.WebApp.close());
}

export type Profile = {
    name: string;
    photo: string;
    about: string;
    super_like: number;
    interests: string[];
}

export default function MyProfile() {
    const {profileData, updateProfile} = useContext(AppPersistenceContext);

    useEffect(() => {
        updateProfile();
    }, [])

    if (!profileData) return <Loading />
    return (
        <div style={{ width: '100%', height: '100%', overflowY: 'auto', textAlign: 'left'}}>
            <AboutMe>
                <div style={{ display: 'flex' }}>
                    <img src={profileData.photo} style={{width: '48px', height: '48px', borderRadius: '24px', objectFit: 'cover'}} alt=""/>
                    <div style={{marginLeft: '16px'}}>
                        <div style={{display: 'flex', fontSize: '16px', fontWeight: 500, gap: '6px'}}>
                            <p>{profileData.name}</p>
                        </div>
                        <p style={{ color: '#5A5A5A', fontSize: '14px' }}>Редактировать профиль</p>
                    </div>
                    <Spacer grow='1'/>
                    <button style={{ border: 'none', backgroundColor: 'transparent'}} onClick={() => onProfileEdit()}>
                        <Edit />
                    </button>
                </div>
            </AboutMe>

            <MySuperLikes>
                <div style={{ display: 'flex', paddingTop: '15px', paddingBottom: '15px', alignItems: 'center' }}>
                    <Star style={{ color: '#AA4EC1' }} />
                    <div style={{marginLeft: '15px'}}>
                        <p style={{fontSize: '16px', fontWeight: 500}}>Ваши суперлайки</p>
                        <p style={{fontSize: '14px', color: '#5A5A5A'}}>Доступно {profileData.super_like} штук</p>
                    </div>
                </div>
            </MySuperLikes>

            <Referal>
                <div style={{ display: 'flex', paddingTop: '15px', paddingBottom: '15px', alignItems: 'center' }}>
                    <Users style={{ color: '#AA4EC1', minWidth: '24px' }}/>
                    <div style={{marginLeft: '15px'}}>
                        <p style={{fontSize: '14px', color: '#5A5A5A'}}>Дарим суперлайк за каждого приглашенного друга!</p>
                    </div>
                    <Spacer grow='1'/>
                    <button style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => onFriendsInvite()}>
                        <Share />
                    </button>
                </div>
            </Referal>

            <div style={{display: 'flex', marginTop: '24px'}}>
                <p style={{ fontWeight: 500, fontSize: '16px'}}>Навыки</p>
                <Spacer grow='1' />
                <button style={{ fontSize: '14px', color: '#5A5A5A', border: 'none', backgroundColor: 'transparent' }} onClick={() => onChangeSkills()}>Изменить</button>
            </div>
            <div style={{ marginTop: '16px', marginRight: '16px', textAlign: 'left', display: 'flex', flexWrap: 'wrap', gap: '8px'}}>
                { Object.entries(profileData.interests || {}).map(([key, value]: [string, any]) => <Tag key={key} tagname={value}/>)}
            </div>
            <div style={{display: 'flex', marginTop: '24px'}}>
                <p style={{ fontWeight: 500, fontSize: '16px'}}>О себе</p>
                <Spacer grow='1' />
                <button style={{ fontSize: '14px', color: '#5A5A5A', border: 'none', backgroundColor: 'transparent'}} onClick={() => onChangeAbout()}>Изменить</button>
            </div>
            <div style={{ backgroundColor: 'white', borderRadius: '16px', paddingLeft: '20px', paddingRight: '20px', paddingTop: '16px', paddingBottom: '16px', marginTop: '16px', textAlign: 'left'}}>
                <p style={{ fontSize: '14px', }}>{profileData.about}</p>
            </div>
        </div>
    );
}

import {ComponentProps} from "react";

export default function Supre(props: ComponentProps<'svg'>) {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g opacity="0.3">
                <path
                    fillRule="evenodd" clipRule="evenodd"
                    d="M25 2.60417C25.863 2.60417 26.5625 3.30373 26.5625 4.16667V8.33334C26.5625 9.19628 25.863 9.89584 25 9.89584C24.1371 9.89584 23.4375 9.19628 23.4375 8.33334V4.16667C23.4375 3.30373 24.1371 2.60417 25 2.60417ZM38.6051 11.3949C39.2153 12.0051 39.2153 12.9944 38.6051 13.6046L37.89 14.3197C37.2798 14.9299 36.2905 14.9299 35.6803 14.3197C35.0701 13.7096 35.0701 12.7202 35.6803 12.11L36.3954 11.3949C37.0056 10.7847 37.9949 10.7847 38.6051 11.3949ZM11.3952 11.3952C12.0054 10.785 12.9947 10.785 13.6049 11.3952L14.32 12.1103C14.9302 12.7205 14.9302 13.7098 14.32 14.32C13.7098 14.9302 12.7205 14.9302 12.1103 14.32L11.3952 13.6049C10.785 12.9947 10.785 12.0053 11.3952 11.3952ZM2.60419 25C2.60419 24.1371 3.30374 23.4375 4.16669 23.4375H8.33335C9.1963 23.4375 9.89585 24.1371 9.89585 25C9.89585 25.8629 9.1963 26.5625 8.33335 26.5625H4.16669C3.30374 26.5625 2.60419 25.8629 2.60419 25ZM40.1042 25C40.1042 24.1371 40.8037 23.4375 41.6667 23.4375H45.8334C46.6963 23.4375 47.3959 24.1371 47.3959 25C47.3959 25.8629 46.6963 26.5625 45.8334 26.5625H41.6667C40.8037 26.5625 40.1042 25.8629 40.1042 25ZM14.3198 35.6803C14.93 36.2905 14.93 37.2798 14.3198 37.89L13.6051 38.6047C12.9949 39.2148 12.0056 39.2148 11.3954 38.6047C10.7852 37.9945 10.7852 37.0051 11.3954 36.3949L12.1101 35.6803C12.7202 35.0701 13.7096 35.0701 14.3198 35.6803ZM35.6802 35.6807C36.2904 35.0705 37.2797 35.0705 37.8899 35.6807L38.6046 36.3954C39.2148 37.0056 39.2148 37.9949 38.6046 38.6051C37.9944 39.2153 37.0051 39.2153 36.3949 38.6051L35.6802 37.8904C35.07 37.2802 35.07 36.2909 35.6802 35.6807ZM25 40.1042C25.863 40.1042 26.5625 40.8037 26.5625 41.6667V45.8333C26.5625 46.6963 25.863 47.3958 25 47.3958C24.1371 47.3958 23.4375 46.6963 23.4375 45.8333V41.6667C23.4375 40.8037 24.1371 40.1042 25 40.1042Z"
                    fill="currentColor"
                />
            </g>
            <path
                d="M22.1532 18.4084C23.4198 16.1361 24.0531 15 25 15C25.9469 15 26.5802 16.1361 27.8468 18.4084L28.1745 18.9962C28.5345 19.6419 28.7144 19.9648 28.9951 20.1778C29.2757 20.3908 29.6251 20.4699 30.3241 20.628L30.9605 20.772C33.4201 21.3286 34.65 21.6068 34.9426 22.5477C35.2352 23.4886 34.3968 24.4691 32.7199 26.4299L32.2861 26.9372C31.8096 27.4944 31.5713 27.773 31.4641 28.1177C31.357 28.4624 31.393 28.8341 31.465 29.5776L31.5306 30.2544C31.7841 32.8706 31.9109 34.1787 31.1449 34.7602C30.3788 35.3417 29.2273 34.8115 26.9243 33.7512L26.3285 33.4768C25.6741 33.1755 25.3469 33.0248 25 33.0248C24.6531 33.0248 24.3259 33.1755 23.6715 33.4768L23.0757 33.7512C20.7727 34.8115 19.6212 35.3417 18.8551 34.7602C18.0891 34.1787 18.2159 32.8706 18.4694 30.2544L18.535 29.5776C18.607 28.8341 18.643 28.4624 18.5359 28.1177C18.4287 27.773 18.1904 27.4944 17.7139 26.9372L17.2801 26.4299C15.6032 24.4691 14.7648 23.4886 15.0574 22.5477C15.35 21.6068 16.5799 21.3286 19.0395 20.772L19.6759 20.628C20.3749 20.4699 20.7243 20.3908 21.0049 20.1778C21.2856 19.9648 21.4655 19.6419 21.8255 18.9962L22.1532 18.4084Z"
                fill="currentColor"
            />
        </svg>
    )
}

import './style.css';

import {LikesButtonWrapper, sendLike} from '../Buttons/Buttons';
import Tag from '../Tag/Tag';

import React, {useState, useEffect, useContext} from 'react';
import {useSwipeable} from 'react-swipeable';

import axios from 'axios';
import Loading from '../Loading/Loading';

import GetHeaders from '../../Misc/GetHeaders';
import {BACKEND_BASE_URL} from "../../config";
import Like from "../../Icons/Like";
import SendSuperLike from "../SendSuperLike/SendSuperLike";
import {AppPersistenceContext} from "../MainCard/MainCard";
import SuperLike from "../../Icons/Super";
import FaceScanCircle from "../../Icons/Face Scan Circle.svg";
import Linkify from 'react-linkify';

export type UserSuggestion = {
    user_id: number;
    name: string;
    about: string;
    photo: string;
    interests: string[];
}

async function loader(unviewedSuggestions: UserSuggestion[] | null, topic?: string): Promise<UserSuggestion[]> {
    const unviewed = unviewedSuggestions?.map(n => n.user_id) || [];
    return await axios.post(BACKEND_BASE_URL + '/api/v1/get_recommend',
        {
            unviewed,
            ...(topic ? {category: topic} : {})
        },
        GetHeaders()
    ).then((response: { data: { recommend: UserSuggestion[] } }) => [
        ...(unviewedSuggestions || []),
        ...response.data.recommend.filter(({user_id}) => !unviewed.includes(user_id))
    ]);
}

export function HomeCarousel() {
    const { suggestions, setSuggestions } = useContext(AppPersistenceContext);
    const loadMore = () => {
        loader(suggestions).then(r => setSuggestions(r))
    }

    function pop() {
        if (suggestions) {
            setSuggestions(suggestions.slice(1));
        }
    }

    useEffect(() => {
        if (!suggestions || suggestions.length <= 3) loadMore();
    }, [suggestions]);

    return <Carousel suggestions={suggestions} pop={pop} />
}

export function TopicCarousel({topic}: {topic: string}) {
    const [ suggestions, setSuggestions ] = useState<UserSuggestion[] | null>(null);
    const loadMore = (preserveSuggestions: boolean = true) => {
        loader(preserveSuggestions ? suggestions : [], topic).then(r => setSuggestions(r))
    }

    function pop() {
        if (suggestions) {
            setSuggestions(suggestions.slice(1));
        }
    }

    useEffect(() => {
        if (suggestions && suggestions.length <= 3) loadMore();
    }, [suggestions]);

    useEffect(() => {
        loadMore(false);
    }, [topic]);

    return <Carousel suggestions={suggestions} pop={pop} />
}

function Carousel({suggestions, pop}: {suggestions: UserSuggestion[] | null, pop: () => void}) {
    const [swipeTo, setSwipeTo] = useState('');
    const [superlikeModalOpen, setSuperlikeModalOpen] = useState(false)
    const [expanded, setExpanded] = useState(false)

    function interaction(liked: boolean) {
        sendLike(currentSuggestion.user_id, liked);
        setSwipeTo(liked ? 'swipe-left' : 'swipe-right');
    }

    const handlers = useSwipeable({
        onSwipedLeft: (_) => interaction(true),
        onSwipedRight: (_) => interaction(false),
        trackMouse: true,
    });

    if (!suggestions) return <Loading/>
    if (suggestions.length === 0) return (
        <div style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '8px',
            borderRadius: '16px',
            border: '1px solid #E2E2E2'
        }}>
            <img src={FaceScanCircle} style={{ width: '128px', marginBottom: '16px' }}/>
            <p style={{fontSize: '16px', fontWeight: 500}}>Новых рекомендаций пока нет</p>
            <p style={{fontSize: '14px', color: '#5A5A5A'}}>Самое время чтобы сделать перерыв</p>
        </div>
    )

    const currentSuggestion = suggestions[0];
    return (
        <div onAnimationEnd={() => {
            pop();
            setSwipeTo('');
        }} className={swipeTo} {...handlers} style={{
            transition: 'opacity 250ms ease-in-out', opacity: swipeTo ? '0' : '1',
            width: '100%', height: '100%', borderRadius: '24px',
            backgroundSize: 'cover', backgroundPosition: 'center',
            backgroundImage: `linear-gradient(to bottom, transparent, rgba(0,0,0,0.75)), url(${currentSuggestion.photo}) `
        }}>

            <div
                style={{textAlign: 'left', height: '100%', width: '100%', padding: '24px 0', display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', boxSizing: 'border-box'}}>
                <div style={{marginLeft: '16px', marginBottom: '24px'}}>
                    <div
                        style={{display: 'flex', color: 'white', fontSize: '22px', gap: '8px'}}>
                        <p style={{textShadow: '1px 1px 2px #000000, 0 0 1em #000000, 0 0 0.2em #000000'}}>{currentSuggestion.name}</p>
                    </div>
                    <div style={{marginTop: '0', marginRight: '16px', display: 'flex', flexWrap: 'wrap', gap: '8px'}}>
                        <p style={{
                            color: '#D6D6D6', marginTop: '8px', textAlign: 'left', fontSize: '16px', textShadow: '1px 1px 2px #000000, 0 0 1em #000000, 0 0 0.2em #000000',
                            overflow: 'hidden', display: '-webkit-box', wordBreak: 'break-word',
                            // @ts-ignore
                            '-webkit-line-clamp': expanded ? '14' : '4',
                            '-webkit-box-orient': 'vertical'
                        }} onClick={() => setExpanded(!expanded)}>
                            <Linkify componentDecorator={(href, text, k) => <a href={href} children={text} key={k} target="_blank" />}>
                                {currentSuggestion.about}
                            </Linkify>
                        </p>
                        <div
                            style={{marginTop: '0', marginRight: '16px', display: 'flex', flexWrap: 'wrap', gap: '8px'}}>
                            {currentSuggestion.interests.slice(0, 5).map((tag) => <Tag key={tag} tagname={tag}/>)}
                        </div>
                    </div>
                </div>

                <div style={{display: 'flex', width: '100%', justifyContent: 'center', gap: '10%', color: 'white'}}>
                    <LikesButtonWrapper onClick={() => interaction(true)}>
                        <Like />
                    </LikesButtonWrapper>
                    <button
                        onClick={() => setSuperlikeModalOpen(true)}
                        style={{width: '64px', height: '64px', backgroundColor: '#AA4EC1', borderRadius: '60px', border: 'none', paddingTop: '2.5px', color: 'currentColor' }}
                    >
                        <SuperLike />
                    </button>
                    <SendSuperLike
                        open={superlikeModalOpen}
                        onDismiss={() => setSuperlikeModalOpen(false)}
                        userId={currentSuggestion.user_id}
                        onSent={() => setSwipeTo('swipe-right')}
                    />
                    <LikesButtonWrapper onClick={() => interaction(false)}>
                        <Like style={{ rotate: '180deg' }} />
                    </LikesButtonWrapper>
                </div>
            </div>
        </div>
    );
}

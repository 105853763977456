import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import MainCard from './Components/MainCard/MainCard';


declare const window: any;
declare const Telegram: any;

window.Telegram.WebApp.expand();
let initData = Telegram.WebApp.initData || '';
let initDataUnsafe = Telegram.WebApp.initDataUnsafe || {};
Telegram.WebApp.setBackgroundColor('#EFEEF4');
Telegram.WebApp.ready()


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <MainCard></MainCard>
  </React.StrictMode>
);

import Spacer from "react-spacer";
import Down from "../../Icons/Chevron";
import Star from "../../Icons/Star";
import Like from "../../Icons/Like";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {BACKEND_BASE_URL} from "../../config";
import GetHeaders from "../../Misc/GetHeaders";
import {Profile} from "../MyProfile/MyProfile";
import Tag from "../Tag/Tag";
import {sendLike} from "../Buttons/Buttons";
import Chevron from "../../Icons/Chevron";

export function AboutMe(props: any) {
    return (
        <div style={{borderRadius:'16px', backgroundColor: 'white', paddingTop: '16px', paddingBottom: '16px', paddingLeft: '16px', paddingRight: '16px', marginBottom: '8px'}}>
            {props.children}
        </div>
    );
}

export function MySuperLikes(props: any) {
    return (
        <div style={{borderRadius:'16px', backgroundColor: '#E9DFEF', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '16px', paddingRight: '20px', marginBottom: '8px'}}>
            {props.children}
        </div>
    );
}

export function Referal(props: any) {
    return (
        <div style={{borderRadius:'16px', backgroundColor: '#E9DFEF', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '16px', paddingRight: '20px', marginBottom: '8px'}}>
            {props.children}
        </div>
    );
}

export type LikeNotification = {
    user_id: number;
    name: string;
    photo: string;
    time: string;
    text?: string;
    type: 'like' | 'superlike';
}

export function LikeCard({notification, onSuperlike}: {notification: LikeNotification, onSuperlike: () => void}) {
    const [extended, setExtended] = useState(false);
    const [extendedData, setExtendedData] = useState<Profile | null>(null);

    useEffect(() => {
        if (extended && !extendedData) {
            axios.post(
                BACKEND_BASE_URL + '/api/v1/get_profile', {user_id: notification.user_id}, GetHeaders()
            ).then((response: { data: { recommend: Profile } }) =>
                (setExtendedData(response.data.recommend),console.log(response))
            );
        }
    }, [extended, extendedData]);

    return (
        <div style={{paddingTop: '20px', backgroundColor: 'white', borderRadius: '16px', paddingBottom: '20px', paddingLeft: '16px', paddingRight: '20px', textAlign: 'left'}}>
            <div style={{ display: 'flex'}}>
                <img src={notification.photo} style={{width: '48px', height: '48px', objectFit: 'cover', borderRadius: '24px'}} alt=""/>
                <div style={{marginLeft: '16px'}}>
                    <div style={{display: 'flex', fontSize: '16px', fontWeight: 500, gap: '6px'}}>
                        <p>{notification.name}</p>
                    </div>
                    <p style={{color: '#5A5A5A', fontSize: '14px', }}>{notification.time}</p>
                </div>
                <Spacer grow='1'/>
                <button style={{ border: 'none', backgroundColor: 'transparent'}} onClick={() => setExtended(!extended)}>
                    <Chevron style={{ transform: `rotate(${extended ? '180deg' : '0deg'})`, transition: 'transform 200ms' }} />
                </button>
            </div>
            {extended && extendedData && (
                <div style={{}}>
                    <p style={{ fontSize: '14px', marginTop: '16px', }}>{extendedData.about}</p>
                    <div style={{ marginTop: '16px', marginRight: '16px', textAlign: 'left', display: 'flex', flexWrap: 'wrap', gap: '8px'}}>
                        { Object.entries(extendedData.interests || {}).map(([key, value]: [string, any]) => <Tag type='darker' key={key} tagname={value}/>)}
                    </div>
                </div>
            )}
            {notification.text && <p style={{ marginTop: '16px', marginBottom: '16px' }}>{notification.text}</p>}
            <div style={{ display: 'flex', gap: '8px', marginTop: '16px'}}>
                <Spacer grow='1'/>
                <button style={{ borderRadius: '36px', height: '40px', width: '40px', backgroundColor: '#E9DFEF', border: 'none'}} onClick={onSuperlike}>
                    <Star style={{ width: '16px', height: '16px', color: '#AA4EC1' }} />
                </button>
                <button style={{ borderRadius: '36px', height: '40px', backgroundColor: 'white', border: '1px solid #E2E2E2'}} onClick={() => sendLike(notification.user_id, true)}>
                    <div className="like_otver_block" style={{ display: 'flex', marginLeft: '12px', marginRight: '16px', marginTop: '10px', marginBottom: '12px'}}>
                        <Like style={{width: '16px', height: '16px'}}/>
                        <p style={{ fontSize: '12px', marginLeft: '4px'}}>Лайкнуть в ответ</p>
                    </div>
                </button>
            </div>
        </div>
    );
}